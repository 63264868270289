import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import { staggerContainer, } from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar, TitleText } from "../components";
import Button from "../components/common/Button";
import {  BgGradient, Shape} from "../assets";

import BuildForAgent from '../assets/images/foragent/build.png' 
import MarketForAgent from '../assets/images/foragent/market.png' 
import ManageForAgent from '../assets/images/foragent/manage.png'

import {  ForAgentImage, ManageImageAgent,MarketImageAgent, } from "../assets";
import TrucstedCompany from '../components/ForAgent/TrucstedCompany';
import Features from '../sections/Features';
import Profile from '../sections/Porfile';
import SecondTestimonials from '../sections/SecondTestimonial/SecondTestimonials';
import RightFor from '../sections/RightFor';
import LearnMore from '../components/ForAgent/LearnMore';

const ForAgent = () => {

  const [showFloatHeader, setShowFloatHeader] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)



  useEffect(() => {
    document.getElementById('layout').addEventListener('scroll', () => {
      document.getElementById('layout').scrollTop > 50 ? setShowFloatHeader(true) : setShowFloatHeader(false);
    })
  })


  return (
    <Layout id="layout" showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}>

      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat gradient_background">

        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

        <section className={`${styles.yPaddings} w-full bg-cover `}>
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidthNew} relative w-4/5  mx-auto pb-6 flex flex-col-reverse xl:flex-row`}>
            <div className="hidden xl:flex items-center justify-end p-10">
              <img
                className="absolute left-0 mt-8 w-[25%]"
                width=""
                src={Shape}
                alt="Workplace"
              />
            </div>

            <div className="w-full xl:w-2/3 mt-8 xl:mt-10 mx-auto flex items-center justify-center">
              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-base xl:text-[34px] lg:text-lg text-primary font-zohoPuvi font-normal ">
                How it works - for brokers
                </h1>
                <TitleText
                  title={
                    <>
                      Everything in your terms, to bring in opportunities that’d really work for you!
                    </>
                  }
                  textStyles="text-center"
                />
                <p className="text-sm  md:text-lg text-center max-w-screen-lg font-Poppins tracking-normal text-font_primary xl:leading-snug">
                The problem is finding high-quality projects you care about and dealing with all the administration and fees that come with it. Let us help you get back to doing more of what you love.
                </p>
                <div className="flex flex-row space-x-4 px-2">
                  <Button text="Sign Up" to="https://platform.realiti.io/" classNames='!rounded-[40px] w-[280px] py-5 !px-[66px] border-[3px] border-[#4A4A4A]'/>
                  
                </div>
              </div>
            </div>

          </motion.div>
        </section>
      </div>

      <div className="">
        <TrucstedCompany/>
        <div className='flex flex-col gap-5 px-5 xl:px-[80px] mb-[50px]'>

        <LearnMore icon={BuildForAgent} title="Build" image={ForAgentImage} bgColor='bg-[#E1F1ED]' description="Design your online store yourself with our drag-and-drop builder and professional store themes. Save on development costs and start selling faster."/>
        <LearnMore icon={ManageForAgent} bgColor='bg-[#D7F0A4]' image={ManageImageAgent} wrapperClass='lg:flex-row-reverse' title="Manage" description="Get all your KYCs done for once so that the partner onboarding is never a tedious process to be done over and again."/>
        <LearnMore icon={MarketForAgent} bgColor='bg-[#F7E0B2] px-[30px]' image={MarketImageAgent} title="Market" description="Design your online store yourself with our drag-and-drop builder and professional store themes. Save on development costs and start selling faster."/>
        </div>
        <Features />
        <Profile />
        <SecondTestimonials />
        <RightFor />        
      </div>
      <Footer />
    </Layout>
  );
};

export default ForAgent;
