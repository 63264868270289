"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { staggerContainer } from "../utils/motion";

import PricingGroup from "../assets/images/pricing-group.png";
// const Rounded = ({ title, subtitle, icon }) => (
//   <div className={`flex flex-col items-center justify-center space-y-5`}>
//     <div
//       className={`flex items-center justify-center w-auto h-36 rounded-full bg-white shadow-md`}
//     >
//       <img src={icon} alt="icon" className="px-3 py-3 w-24" />
//     </div>
//     <div>
//       <p className="font-medium text-[18px] text-[#090909] leading-[32.4px]">
//         {title}
//       </p>
//     </div>
//   </div>
// );

const Categories = () => {
  return (
    <section className={`${styles.paddings}`}>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}
      >
        <h1 className="mb-5 mt-10 text-black text-xl md:leading-[83px] md:text-[55px]  text-center font-bold">Your Own Website can Replace</h1>
        <h3 className="flex text-xl mb-5 md:leading-[83px] md:text-[55px]  text-[#F7941D] font-bold text-center items-center mx-auto gap-x-3">
          100’S of{" "}
          <span className="relative sm:block hidden">
            <svg
              width="36"
              height="32"
              viewBox="0 0 36 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.7121 27.1567C35.4121 27.9567 34.9122 28.4565 34.1122 28.7565C32.5122 29.4565 30.8121 30.2565 29.2121 30.9565C27.6121 31.6565 26.1121 31.0565 25.5121 29.4565C23.9121 25.2565 22.4122 21.1565 20.8122 16.9565C20.6122 16.4565 20.4121 15.8566 20.2121 15.3566C20.1121 15.0566 20.0121 15.0567 19.7121 15.1567C18.2121 15.8567 16.6122 16.5565 15.1122 17.2565C12.3122 18.4565 9.51214 19.7565 6.71214 20.9565C6.31214 21.1565 5.91215 21.3565 5.41215 21.4565C4.11215 21.8565 2.81218 21.2565 2.31218 19.9565C1.61218 18.1565 0.91214 16.2566 0.21214 14.3566C-0.28786 12.9566 0.512152 11.3567 1.91215 10.6567C3.01215 10.1567 4.21218 9.65668 5.31218 9.15668C11.0122 6.65668 16.7122 4.05658 22.4122 1.55658C22.8122 1.35658 23.3121 1.15648 23.7121 0.956485C25.2121 0.356485 26.7122 0.956485 27.3122 2.45648C28.4122 5.35648 29.5122 8.35653 30.6122 11.2565C31.6122 13.8565 32.6122 16.5565 33.6122 19.2565C34.3122 21.1565 35.0121 23.0565 35.7121 24.9565C36.1121 25.6565 36.0121 26.3567 35.7121 27.1567Z"
                fill="url(#paint0_linear_1_344)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_344"
                  x1="0.129441"
                  y1="16.0277"
                  x2="35.9855"
                  y2="16.0277"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2E31FF" />
                  <stop offset="1" stop-color="#F69722" />
                </linearGradient>
              </defs>
            </svg>
            <span className="absolute -top-10">
              <svg
                width="128"
                height="30"
                viewBox="0 0 128 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.2121 2.25781C4.4121 1.25781 6.01208 0.757812 8.01208 0.757812C9.31209 0.757812 10.5121 0.957764 11.4121 1.45776C12.3121 1.95776 13.1121 2.55791 13.6121 3.35791C14.1121 4.15791 14.3121 5.05796 14.3121 6.15796C14.3121 7.35796 14.0121 8.35796 13.4121 9.15796C12.8121 9.95796 12.0121 10.5579 11.2121 10.8579V10.9578C12.3121 11.2578 13.2121 11.9579 13.8121 12.8579C14.4121 13.7579 14.7121 14.8578 14.7121 16.2578C14.7121 17.3578 14.4121 18.3578 13.9121 19.2578C13.4121 20.1578 12.6121 20.8579 11.6121 21.3579C10.6121 21.8579 9.41208 22.158 8.01208 22.158C6.01208 22.158 4.31208 21.6579 3.01208 20.5579C1.71209 19.4579 1.01211 17.9579 0.912109 16.0579H4.01208C4.11208 17.0579 4.5121 17.8579 5.2121 18.5579C5.9121 19.1579 6.81209 19.5579 8.01208 19.5579C9.21208 19.5579 10.0121 19.2579 10.7121 18.5579C11.4121 17.8579 11.6121 17.0579 11.6121 16.0579C11.6121 14.7579 11.2121 13.7578 10.3121 13.2578C9.51213 12.6578 8.21211 12.4578 6.41211 12.4578H5.7121V9.75781H6.51208C8.01208 9.75781 9.21208 9.45776 10.0121 8.95776C10.8121 8.45776 11.2121 7.65796 11.2121 6.65796C11.2121 5.75796 10.9121 5.05776 10.3121 4.45776C9.71213 3.85776 8.91213 3.65796 7.81213 3.65796C6.71213 3.65796 5.91213 3.95776 5.31213 4.45776C4.71213 4.95776 4.41213 5.65776 4.31213 6.45776H1.2121C1.3121 4.65776 2.0121 3.25781 3.2121 2.25781Z"
                  fill="url(#paint0_linear_1_335)"
                />
                <path
                  d="M23.6122 6.75854C24.4122 6.35854 25.3122 6.05859 26.4122 6.05859V9.35864H25.6122C24.3122 9.35864 23.4122 9.65864 22.7122 10.3586C22.0122 10.9586 21.7122 12.1587 21.7122 13.6587V21.8586H18.5122V6.25854H21.7122V8.55859C22.2122 7.85859 22.9122 7.25854 23.6122 6.75854Z"
                  fill="url(#paint1_linear_1_335)"
                />
                <path
                  d="M29.3123 9.95801C30.0123 8.75801 30.8123 7.7582 32.0123 7.1582C33.1123 6.4582 34.4123 6.1582 35.8123 6.1582C36.8123 6.1582 37.8123 6.35815 38.8123 6.85815C39.8123 7.35815 40.6123 7.8582 41.2123 8.6582V1.1582H44.5123V22.1582H41.2123V19.7581C40.7123 20.5581 39.9123 21.1582 39.0123 21.6582C38.1123 22.1582 37.0123 22.3582 35.8123 22.3582C34.4123 22.3582 33.2123 22.0582 32.1123 21.3582C31.0123 20.6582 30.1123 19.658 29.4123 18.458C28.7123 17.258 28.4123 15.8581 28.4123 14.2581C28.3123 12.5581 28.7123 11.158 29.3123 9.95801ZM40.5123 11.3582C40.1123 10.5582 39.5123 9.95811 38.8123 9.55811C38.1123 9.15811 37.3123 8.95801 36.5123 8.95801C35.7123 8.95801 34.9123 9.15811 34.2123 9.55811C33.5123 9.95811 32.9123 10.5582 32.5123 11.3582C32.1123 12.1582 31.8123 13.0582 31.8123 14.1582C31.8123 15.2582 32.0123 16.158 32.5123 16.958C33.0123 17.758 33.5123 18.3582 34.3123 18.8582C35.0123 19.2582 35.8123 19.458 36.6123 19.458C37.4123 19.458 38.2123 19.2582 38.9123 18.8582C39.6123 18.4582 40.2123 17.8581 40.6123 17.0581C41.0123 16.2581 41.3123 15.3581 41.3123 14.2581C41.3123 13.1581 40.9123 12.1582 40.5123 11.3582Z"
                  fill="url(#paint2_linear_1_335)"
                />
                <path
                  d="M69.1121 10.9578C68.6121 11.8578 67.9121 12.558 66.8121 13.158C65.7121 13.758 64.4121 13.9578 62.8121 13.9578H59.2121V21.9578H56.0121V2.25781H62.8121C64.3121 2.25781 65.6121 2.55786 66.6121 3.05786C67.6121 3.55786 68.4121 4.25796 69.0121 5.15796C69.5121 6.05796 69.8121 7.05796 69.8121 8.15796C69.8121 9.15796 69.6121 10.0578 69.1121 10.9578ZM65.6121 10.5579C66.2121 9.95786 66.5121 9.25796 66.5121 8.15796C66.5121 5.95796 65.3121 4.95776 62.8121 4.95776H59.2121V11.3579H62.8121C64.1121 11.3579 65.0121 11.0579 65.6121 10.5579Z"
                  fill="url(#paint3_linear_1_335)"
                />
                <path
                  d="M72.812 9.95801C73.512 8.75801 74.312 7.7582 75.512 7.1582C76.612 6.4582 77.912 6.1582 79.212 6.1582C80.412 6.1582 81.512 6.35815 82.412 6.85815C83.312 7.35815 84.112 7.9582 84.612 8.6582V6.35815H87.912V21.958H84.612V19.6582C84.112 20.3582 83.312 21.058 82.412 21.458C81.512 21.958 80.412 22.1582 79.212 22.1582C77.912 22.1582 76.612 21.8582 75.512 21.1582C74.412 20.4582 73.512 19.4581 72.812 18.2581C72.112 17.0581 71.812 15.6581 71.812 14.0581C71.812 12.5581 72.212 11.158 72.812 9.95801ZM84.012 11.3582C83.612 10.5582 83.012 9.95811 82.312 9.55811C81.612 9.15811 80.812 8.95801 80.012 8.95801C79.212 8.95801 78.412 9.15811 77.712 9.55811C77.012 9.95811 76.412 10.5582 76.012 11.3582C75.612 12.1582 75.312 13.0582 75.312 14.1582C75.312 15.2582 75.512 16.158 76.012 16.958C76.512 17.758 77.012 18.3582 77.812 18.8582C78.512 19.2582 79.312 19.458 80.112 19.458C80.912 19.458 81.712 19.2582 82.412 18.8582C83.112 18.4582 83.712 17.8581 84.112 17.0581C84.512 16.2581 84.812 15.3581 84.812 14.2581C84.812 13.1581 84.412 12.1582 84.012 11.3582Z"
                  fill="url(#paint4_linear_1_335)"
                />
                <path
                  d="M97.312 6.75854C98.112 6.35854 99.0119 6.05859 100.112 6.05859V9.35864H99.312C98.012 9.35864 97.1119 9.65864 96.4119 10.3586C95.7119 10.9586 95.4119 12.1587 95.4119 13.6587V21.8586H92.2119V6.25854H95.4119V8.55859C95.9119 7.85859 96.512 7.25854 97.312 6.75854Z"
                  fill="url(#paint5_linear_1_335)"
                />
                <path
                  d="M106.812 8.9585V17.6587C106.812 18.2587 106.912 18.6585 107.212 18.9585C107.512 19.2585 107.912 19.3586 108.612 19.3586H110.612V22.0586H108.012C106.512 22.0586 105.412 21.7586 104.712 21.0586C104.012 20.3586 103.512 19.2585 103.512 17.7585V9.05859H101.712V6.4585H103.512V2.55859H106.812V6.4585H110.612V9.05859H106.812V8.9585Z"
                  fill="url(#paint6_linear_1_335)"
                />
                <path
                  d="M127.712 6.35742L118.112 29.3574H114.812L118.012 21.7573L111.812 6.35742H115.412L119.812 18.2573L124.412 6.35742H127.712Z"
                  fill="url(#paint7_linear_1_335)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_335"
                    x1="-13.5737"
                    y1="38.4482"
                    x2="146.434"
                    y2="-10.8171"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E31FF" />
                    <stop offset="1" stop-color="#F69722" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1_335"
                    x1="-13.5737"
                    y1="38.4488"
                    x2="146.435"
                    y2="-10.817"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E31FF" />
                    <stop offset="1" stop-color="#F69722" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_1_335"
                    x1="-13.5735"
                    y1="38.4482"
                    x2="146.434"
                    y2="-10.8173"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E31FF" />
                    <stop offset="1" stop-color="#F69722" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_1_335"
                    x1="-13.574"
                    y1="38.4478"
                    x2="146.434"
                    y2="-10.8182"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E31FF" />
                    <stop offset="1" stop-color="#F69722" />
                  </linearGradient>
                  <linearGradient
                    id="paint4_linear_1_335"
                    x1="-13.5737"
                    y1="38.4483"
                    x2="146.434"
                    y2="-10.8172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E31FF" />
                    <stop offset="1" stop-color="#F69722" />
                  </linearGradient>
                  <linearGradient
                    id="paint5_linear_1_335"
                    x1="-13.5742"
                    y1="38.4488"
                    x2="146.434"
                    y2="-10.817"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E31FF" />
                    <stop offset="1" stop-color="#F69722" />
                  </linearGradient>
                  <linearGradient
                    id="paint6_linear_1_335"
                    x1="-13.5742"
                    y1="38.4487"
                    x2="146.434"
                    y2="-10.8172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E31FF" />
                    <stop offset="1" stop-color="#F69722" />
                  </linearGradient>
                  <linearGradient
                    id="paint7_linear_1_335"
                    x1="-13.5735"
                    y1="38.4475"
                    x2="146.434"
                    y2="-10.8179"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E31FF" />
                    <stop offset="1" stop-color="#F69722" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
          </span>{" "}
          Real Estate Portals & Apps
        </h3>
        <p className="sm:text-[25px] text-sm text-black font-medium mb-5  md:mb-[110px] text-center">
          With realiti.io, we help you focus on the business while all aspects
          of tech, end-to-end, is taken care of!
        </p>
        <img src={PricingGroup} alt="pricing-group" />
      </motion.div>
    </section>
  );
};

export default Categories;