import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import ForBrokers from "./Pages/ForBrokers";
import './App.css';
import HowItsWork from "./Pages/How-its-works";
import ForBuilders from "./Pages/ForBuilders";
import Blogs from "./Pages/Blogs";
import GettingStarted from "./Pages/Blogs/getting-started";
import AboutUs from "./Pages/About";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import RefundPolicy from "./Pages/RefundPolicy";
import ContactUs from "./Pages/ContactUs";
import Price1 from "./Pages/Price-1";
import Price from "./Pages/Price";
import OnlineStorePage from "./Pages/OnlineStore";
import './styles/globals.css';
import ForAgent from "./Pages/For-Agent";
import WebPortal from "./Pages/WebPortal";
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Black.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Bold Italic.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Bold.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Extrablack.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Extrabold.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Light.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Medium.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Regular Italic.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Regular.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Semibold Italic.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Semibold.ttf'
import '../src/components/Fonts/OpenType-TT/Zoho Puvi Thin.ttf'
import IDXPage from "./Pages/IDXPage";
import MLSPage from "./Pages/MLSPage";
import CRMPage from "./Pages/CRMPage";

function App() {
  return (
    <>
            <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/agent' element={<ForAgent/>} />
            <Route path='/web-portal' element={<WebPortal/>} />
            <Route path='/channel-partners' element={<IDXPage/>} />
            <Route path='/stocks' element={<MLSPage/>} />
            <Route path='/leads' element={<CRMPage />} />
            <Route path='/online-store' element={<OnlineStorePage/>} />
            <Route path='/for-brokers' element={<ForBrokers/>} />
            <Route path='/how-its-works' element={<HowItsWork/>} />
            <Route path='/developers' element={<ForBuilders/>} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/price' element={<Price />} />
            <Route path='/price1' element={<Price1 />} />
            <Route path='/blogs' element={<Blogs />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='/refund-policy' element={<RefundPolicy />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/blogs/getting-started' element={<GettingStarted />} />

          </Routes>
    </>
  );
}


export default App;
