import React, { useEffect, useState } from "react"; 
import Sidebar from "./common/Sidebar"; 
import FloatHeader from "./FloatHeader"; 
 
const Layout = ({ 
  children, 
  id, 
  showSideBar, 
  setShowSideBar, 
  showFloatHeader, 
}) => { 
  const [isVisible, setIsVisible] = useState(false); 
 
  useEffect(() => { 
    // Function to handle scroll behavior 
    function handleScroll() { 
      if (window.pageYOffset > 300) { 
        // Adjust the threshold as needed 
        setIsVisible(true); 
      } else { 
        setIsVisible(false); 
      } 
    } 
 
    // Listen to scroll events 
    window.addEventListener("scroll", handleScroll); 
 
    // Clean up 
    return () => { 
      window.removeEventListener("scroll", handleScroll); 
    }; 
  }, []); 
 
  // Function to scroll to the top of the page smoothly 
  function scrollToTop() { 
    window.scrollTo({ 
      top: 0, 
      behavior: "smooth", 
    }); 
  } 
  return ( 
    <> 
      {isVisible && ( 
        <button 
          onClick={scrollToTop} 
          className="fixed bottom-5 right-5 z-[999] animate-bounce bg-white dark:bg-slate-800 p-2 w-14 h-14  ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg shadow-violet-500/50 rounded-full flex items-center justify-center" 
        > 
          <svg 
            class="w-6 h-6 text-violet-500 rotate-180" 
            fill="none" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            viewBox="0 0 24 24" 
            stroke="currentColor" 
          > 
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path> 
          </svg> 
        </button> 
      )} 
      <div id={id} className={`w-full h-screen mx-auto`}> 
        {/* NavBar */} 
        <FloatHeader 
          showFloatHeader={showFloatHeader} 
          showSideBar={showSideBar} 
          setShowSideBar={setShowSideBar} 
        /> 
 
        {/* SideBar */} 
        {showSideBar && ( 
          <div 
            className="absolute w-screen h-screen bg-opacity-60" 
            style={{ zIndex: 1000 }} 
          ></div> 
        )} 
        <Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} /> 
 
        <main className="w-full h-screen">{children}</main> 
 
        {/* <Footer /> */} 
      </div> 
    </> 
  ); 
}; 
 
export default Layout;