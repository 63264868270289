'use client';
import { motion } from 'framer-motion';

import LogoCard from '../LogoCard';
import { WebPortalclient } from '../../constants';
import { NewHeading } from '../CustomTexts';
import styles from '../../styles';
import { fadeIn, staggerContainer } from '../../utils/motion';

const WebPortalTrusted = () => {

  return (
    <section className={`${styles.paddings} bg-[#E6F0D3]`} >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}
      >
        <NewHeading
          title={<> Trusted by <span className='text-primary'>1000+
          </span> realtors</>}
         
          textStyles="mx-auto text-center max-w-[850px]"
        />
        <div className="mt-[50px] flex lg:flex-row flex-col px-2  gap-5">
          {WebPortalclient.map((logos, index) => (
            <motion.div
            variants={fadeIn('up', 'spring', index * 0.5, 1)}
            key={logos.key}
            >            
           <img src={logos.url} alt={logos.title} className='' />
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default WebPortalTrusted;
