"use client";

import { motion } from "framer-motion";

import { Ellipse ,EllipseRight} from "../../assets";
import { fadeIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";
import {  WebPortalProfileData } from "../../constants";
import { NewHeading, SubNewHeading } from "../CustomTexts";

export const ProfileCard = ({
  key,
  id,
  title,
  index,
  paragraph,
  bg,
  text,
  icon,
}) => {
  return (
    <>
      <motion.div
        variants={fadeIn("right", "spring", index * 0.5, 0.75)}
        key={key}
        className={`pb-8 w-full flex flex-col space-y-2 text-center items-center  justify-center  `}
      >
        <img src={icon} alt="profile-image"/>
        <div className="w-full flex flex-col items-center  justify-center  text-center space-y-3 ">
          <h1 className="text-base  xl:text-2xl font-medium text-black">
            {title}
          </h1>
          <p className="w-2/3 lg:w-4/5 text-sm  text-font_primary">
            {paragraph}
          </p>
        </div>
      </motion.div>
    </>
  );
};

const RealticWork = () => {
  return (
    <section className={`${styles.paddings} relative z-10 2xl:py-[100px] `}>
      <NewHeading
        title={
          <>
            This is how <span className="text-primary"> Realiti.io</span>works
          </>
        }
        textStyles="text-center !text-[#0E2278] !mt-20"
      />
      <SubNewHeading
        title={
          <>We ensure the legitimacy of every stakeholder you partner with!</>
        }
        textStyles="!text-[#595B62] text-center font-normal mb-10"
      />
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col lg:flex-row items-start justify-center`}
      >
        {WebPortalProfileData.map((world, index) => (
          <>
            <ProfileCard key={world.id} {...world} index={index} />
           {world.id === 1? <img
              src={Ellipse}
              alt="line"
              className='hidden lg:block'
            />:null}
             {world.id === 2?<img
              src={EllipseRight}
              alt="line"
              className="hidden lg:block relative top-[30px]"
            />:null}
          </>
        ))}
      </motion.div>
    </section>
  );
};

export default RealticWork;
