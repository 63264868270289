"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { FutureData } from "../constants";
import { staggerContainer } from "../utils/motion";
import { Heading, NewHeading } from "../components/CustomTexts";
import { FutureBg } from "../assets";
import FutureCard from "../components/FutureCard";

const Future = () => {

  return (
    <section id="features"
      style={{ backgroundImage: `url(${FutureBg})` }}
      // className={`${styles.paddings}`}
      >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col px-0 lg:px-12`}>
        <NewHeading
          title={
            <>
              The Future of <span className="text-primary"> Realiti</span> is Here !
            </>
          }
          textStyles="text-center text-white !mt-20"
        />
        <div className=" px-2 lg:px-4 mt-[50px] flex lg:flex-row flex-col gap-5 mb-8">
          {FutureData.map((world, index) => (
            <FutureCard
              key={world.id}
              {...world}
              index={index}
            // active={active}
            // handleClick={setActive}
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Future;
