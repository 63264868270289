import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import {
  staggerContainer,
} from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar, TitleText } from "../components";
import Button from "../components/common/Button";
import { BgGradient } from "../assets";
import Insights from "../sections/Insights";
import LeadingBuilders from '../sections/LeadingBuilders';
import DevelopersTrustedCompany from '../components/Builders/TrustedCompany';



const HowItsWork = () => {
  
  const [showFloatHeader, setShowFloatHeader] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)

 

  useEffect(() => {
    document.getElementById('layout').addEventListener('scroll', () => {
      document.getElementById('layout').scrollTop > 50 ? setShowFloatHeader(true) : setShowFloatHeader(false);
    })
  })



  return (
    <Layout id="layout" showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}>

      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat bg-cover">

        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

        <section className={`${styles.yPaddings} w-full bg-cover `}>
        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidthNew} relative w-4/5  mx-auto pb-6 flex flex-col-reverse xl:flex-row`}>
           

            <div className="w-full xl:w-2/3 mt-8 xl:mt-10 mx-auto flex items-center justify-center">
              <div className="flex flex-col items-center justify-center space-y-6">
                
                <TitleText
                  title={
                    <>
                      How its works
                    </>
                  }
                  textStyles="text-center"
                />
                <p className="text-sm  md:text-lg text-center max-w-screen-lg font-Poppins tracking-normal text-font_primary xl:leading-snug">
                It is as simple as how you would use Whatsapp. With Realiti.io it just take three simple stepsto get your real estate business up and running…
                </p>
                <div className="flex flex-row space-x-4 px-2">
                  <Button text="Join our Partner Network" to="https://platform.realiti.io/" classNames='!rounded-[40px] w-[280px] py-5 !px-[66px] border-[3px] border-[#4A4A4A]'/>
                </div>
              </div>
            </div>

          </motion.div>
        </section>

      </div>

      <div className="relative">
      <DevelopersTrustedCompany/>
        <Insights />
      </div>
      <Footer />
    </Layout>
  );
};

export default HowItsWork;
