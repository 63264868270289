import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import { staggerContainer } from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar, TitleText } from "../components";
import Button from "../components/common/Button";
import { BgGradient, Shape } from "../assets";
import { WahatsAppImage, WebPortalImage } from "../assets";
import WebPortalTrusted from "../components/WebPortal/PortalTrusted";
import Replacing from "../components/WebPortal/Replacing";
import MarketingPlatform from "../components/WebPortal/MarketingPlatform";
import TestimonialWeb from "../components/WebPortal/TestimonilaWeb";
import RealticWork from "../components/WebPortal/RealticWorks";
import DragAndDrop from "../components/WebPortal/DragAndDrop";
import VerifyForms from "../components/Forms/VerifyForms";
import Views from "../assets/images/customer/views.png";

const CRMPage = () => {
  const [showFloatHeader, setShowFloatHeader] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    document.getElementById("layout").addEventListener("scroll", () => {
      document.getElementById("layout").scrollTop > 50
        ? setShowFloatHeader(true)
        : setShowFloatHeader(false);
    });
  });

  return (
    <Layout
      id="layout"
      showFloatHeader={showFloatHeader}
      showSideBar={showSideBar}
      setShowSideBar={setShowSideBar}
    >
      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat"
      >
        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

        <section className={`${styles.yPaddings} w-full bg-cover `}>
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidthNew} relative w-4/5  mx-auto pb-6 flex flex-col-reverse xl:flex-row`}
          >
            <div className="hidden xl:flex items-center justify-end p-10">
              <img
                className="absolute left-0 mt-8 w-[25%]"
                width=""
                src={Shape}
                alt="Workplace"
              />
            </div>

            <div className="w-full xl:w-2/3 mt-8 xl:mt-10 mx-auto flex items-center justify-center">
              <div className="flex flex-col items-center justify-center space-y-6">
                
                <TitleText
                  title={
                    <>
                      <span>
                      Seal the Deal, {" "}
                        <span className="font-bold text-primary">
                        Realiti-preneurs!
                        </span>{" "}

                      </span>
                    </>
                  }
                  textStyles="text-center"
                />
                <p className="text-sm  md:text-lg text-center max-w-screen-lg font-zohoPuvi tracking-normal text-font_primary xl:leading-snug">
                Connect, converse, and captivate real estate buyers with seamless interactions.


                </p>
                <div className="flex flex-row items-center space-x-5 px-2">
                  <Button
                    text="Get Started"
                    to="https://platform.realiti.io/"
                    classNames="!rounded-[40px] w-[280px] p-2.5 lg:py-5 lg:!px-[66px] border-[3px] border-[#4A4A4A]"
                  />
                  <p className="text-[#4A4A4A] flex items-center space-x-2 text-xl font-normal">
                    <img src={WahatsAppImage} alt="whatsapp-icon" />
                    <span>Schedule a demo</span>
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          <div className="mx-auto max-w-7xl flex justify-center my-5">

          <VerifyForms />
          </div>
          <img
            src={Views}
            alt="web-portal"
            className="mx-auto my-10 max-w-7xl"
          />
        </section>
      </div>

      <div>
        <WebPortalTrusted />
        <MarketingPlatform />
        <DragAndDrop />
        <RealticWork />
        <Replacing />
        <TestimonialWeb />
      </div>
      <Footer />
    </Layout>
  );
};

export default CRMPage;
