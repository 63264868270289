import React, { useEffect, useRef } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import Button from '../common/Button';

const VerifyForms=()=>{
    const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      intlTelInput(inputRef.current);
    }
  }, []);
    return(
        <div>
            <form className="flex flex-wrap px-5 justify-center gap-2.5">
                <input type="text" className="rounded-[40px] border border-font_primary py-4 pl-[46px] pr-3" placeholder="Email"/>
                <input type="tel" className="rounded-[40px] border border-font_primary py-4 pr-3 pl-[30px]" ref={inputRef}  placeholder="Phone number"/>
                <Button
                    text="Get in Touch"
                    classNames="!rounded-[40px] w-[280px] py-3.5 !px-[66px] border-[3px] border-[#4A4A4A]"
                  />
            </form>
        </div>
    )
}

export default VerifyForms;