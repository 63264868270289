"use client";

import { Logo, MenuIcon } from "../assets";
import NavLink from "./common/Navlink";
import Button from "./common/Button";
import SubNavLink from "./common/SubNavLink";

const Navbar = ({ showSideBar, setShowSideBar }) => {

  return (
    <nav
      // style={{backgroundImage: `url(${NavBg})`}}
      className="w-full bg-no-repeat  bg-transparent flex flex-col items-center z-20">


      <div className="w-[93%] mx-auto flex justify-between items-center mt-6">
        {/* Logo */}
        <a
          href="/"
          className="flex  flex-row items-center space-x-4 cursor-pointer">
          <img className="w-[140px]" src={Logo} alt="Logo" />
        </a>

        {/* Links */}
        <div className="hidden xl:flex flex-row items-center justify-between space-x-6">
          <NavLink to="/agent" text="For Agent " />
          <NavLink to="/developers" text="For Developer" />
          <SubNavLink />
        <Button text="Login" to="https://app.realiti.io/" bgColor="bg-black" classNames="rounded-[0px] py-5 !px-[50px]"/>
      </div>

      {/* Collapse button for mobile view */}
      <div
        className="block xl:hidden cursor-pointer  bg-primary p-2 rounded-10px "
        onClick={() => setShowSideBar(!showSideBar)}>
        <img src={MenuIcon} alt="icon" />
      </div>
    </div>
    </nav >
  )
}

export default Navbar;
