import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import {
  staggerContainer,
} from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar, TitleText } from "../components";
import Button from "../components/common/Button";
import { BgGradient,  } from "../assets";
import LeadingBuilders from '../sections/LeadingBuilders';
import HowWeWork from '../sections/HowWeWork';
import Confidently from '../sections/Confidently';
import Curious from '../sections/Curious';
import DevelopersTrustedCompany from '../components/Builders/TrustedCompany';
const ForBuilders = () => {
  
    const [showFloatHeader, setShowFloatHeader] = useState(false)
    const [showSideBar, setShowSideBar] = useState(false)

   

  useEffect(()=> {
      document.getElementById('layout').addEventListener('scroll', () => {
          document.getElementById('layout').scrollTop > 50 ? setShowFloatHeader(true) : setShowFloatHeader(false);
      })
  })


  

  
  return (
    <Layout id="layout" showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}>

      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat bg-cover gradient_background">

        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

        <section className={`${styles.yPaddings} w-full bg-cover `}>
        
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} relative w-4/5  mx-auto pb-6 flex flex-col-reverse xl:flex-row`}>
            

            <div className="w-full xl:w-2/3 mt-8 xl:mt-10 mx-auto flex items-center justify-center">
              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-base xl:text-[34px] lg:text-lg text-primary font-zohoPuvi font-normal ">
                Why Realiti
                </h1>
                <TitleText
                  title={
                    <>
                     Find top realtors to partner with, in a single click!
                    </>
                  }
                  textStyles="text-center text-[#0E2278]"
                />
                <p className="text-sm md:text-lg text-center font-zohoPuvi tracking-normal text-font_primary xl:leading-snug">
                The problem is finding high-quality projects you care about and dealing with all the administration and fees that come with it. Let us help you get back to doing more of what you love.
                </p>
                <div className="flex flex-row space-x-4 px-2 mt-5">
                  <Button text="Join our Partner Network" to="/" classNames='!rounded-[40px] w-[280px] py-5 !px-[66px] border-[3px] border-[#4A4A4A]'/>
                  
                </div>
              </div>
            </div>

          </motion.div>
        </section>
     
      </div>

      <div className="relative">
        <DevelopersTrustedCompany/>
        <HowWeWork/>
        <Confidently/>
        <Curious/>
      </div>
      <Footer />
    </Layout>
  );
};

export default ForBuilders;
