"use client";

import { motion } from "framer-motion";
import Slider from "react-slick";

import { NewHeading, SubNewHeading } from "../CustomTexts";
import { fadeIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMemo } from "react";
import SliderCard from "./SliderCard";

const DragAndDrop = () => {
    const settings = useMemo(() => {
        return {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
        };
    }, []);
  return (
    <section
      className={`${styles.paddings} relative font-zohoPuvi z-10 bg-[#F7E0B2] `}
    >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} py-5 lg:py-14 px-4 justify-between mx-auto w-full rounded-[38px] space-y-[30px] lg:space-y-0 flex lg:flex-row flex-col-reverse`}
      >
        <motion.div
          variants={fadeIn("left", "tween", 0.2, 1)}
          className="flex max-w-[560px] justify-center flex-col"
        >
          <NewHeading
            title={
              <>
                <span className="text-[#0E2278]">
                  No Coding Involved.
                  <span className="text-primary">No Drag & Drop</span>
                  Involved Either!
                </span>
              </>
            }
            className="!text-[#0E2278]"
          />
          <div className="mt-[10px] flex flex-col gap-[24px]">
            <SubNewHeading
              title={
                <>
                  {" "}
                  However easier the features may be, it is a task to sit and
                  work on the design. We know you & have you covered.{" "}
                </>
              }
              textStyles="w-4/5 !text-[#292929]"
            />
          </div>
        </motion.div>
        <motion.div variants={fadeIn("right", "tween", 0.2, 1)} className={`!max-w-[554px] !mb-[50px] lg:!mb-0`}>
          <Slider {...settings} cz>
            {
                [0,1,2,3,4,5].map((index)=>{
                    return(

                        <SliderCard />
                    )
                })
            }
          </Slider>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default DragAndDrop;
