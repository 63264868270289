import { FolderIcon } from "../../assets/icons/FolderIcon";
import { MenuIcon } from "../../assets/icons/MenuIcon";
import { ProfileIcon } from "../../assets/icons/ProfileIcon";
import { TeamFeedIcon } from "../../assets/icons/TeamFeedIcon";
import HighQualityImage from "../../assets/images/customer/head-quaters.png";
import TeamFeeds from "../../assets/images/customer/team-feeds.png";
import Views from "../../assets/images/customer/views.png";
import AgentQue from "../../assets/images/customer/agent-que.png";
import { useEffect, useState } from "react";

export const CustomerTab = () => {
  const [tabId, setTabId] = useState(0);
  const data = [
    {
      id: 0,
      icon: <MenuIcon />,
      iconFill: <MenuIcon stroke="#000000" />,
      title: "Web portal",
    },
    {
      id: 1,
      icon: <TeamFeedIcon />,
      iconFill: <TeamFeedIcon fill="#fffff" />,
      title: "IDX",
    },
    {
      id: 2,
      icon: <FolderIcon />,
      iconFill: <FolderIcon fill="#000000" />,
      title: "MLS",
    },
    {
      id: 3,
      icon: <ProfileIcon />,
      iconFill: <ProfileIcon fill="#000000" />,
      title: "CRM",
    },
  ];

  const Image = [
    {
      id: 0,
      image: HighQualityImage,
    },
    {
      id: 1,
      image: TeamFeeds,
    },
    {
      id: 2,
      image: Views,
    },
    {
      id: 3,
      image: AgentQue,
    },
  ];

  const handelTab = (id) => {
    setTabId(id);
  };

  useEffect(() => {
    setTimeout(() => {
      if(tabId >= 3){
        setTabId(0)
      }else{
        setTabId(tabId + 1)
      }
      
    },5000)
  },[tabId])

  return (
    <section className="mx-auto my-7">
      <div className="text-center">
        <h1 className="lg:text-5xl text-base font-bold">
          Who says customer <br /> service is tough?
        </h1>
      </div>

      <div className="customer_tab rounded-lg mt-10 max-w-[998px] mx-auto">
        {/* <div className="p-5 hidden md:flex lg:flex-row flex-col gap-y-5 lg:gap-y-0 justify-between items-center">
          <div className="flex sm:flex-row flex-col  items-center text-black sm:space-x-[54px]">
            <MessageIcon />
            <ul className="flex sm:flex-row flex-col  items-center gap-y-2.5 sm:gap-x-10">
              <li className="underline ">Tickets</li>
              <li>Analytics</li>
              <li>Knowledge Base</li>
              <li>Social</li>
            </ul>
          </div>
          <div className="flex items-center text-black gap-x-5">
            <h6>All Departments</h6>
            <SearchIcon />
            <GameIcon />
            <SettingsIcon />
          </div>
        </div> */}
        <div className="flex">
          <ul>
            {data.map((list) => {
              return (
                <li
                  className={`px-[25px] py-2.5 max-w-[94px] flex flex-col items-center cursor-pointer ${
                    tabId === list.id ? "bg-black text-white" : ""
                  }`}
                  key={list.id}
                  onClick={() => handelTab(list.id)}
                >
                  {tabId === list.id ? <>{list.icon}</> : <>{list.iconFill}</>}
                  <h6 className="text-center">{list.title}</h6>
                </li>
              );
            })}
          </ul>
          <div className="bg-[#e5e9f0] flex items-center lg:block">
          {Image.map((staticimage) => {
              return (
                <img
                  src={staticimage.image}
                  alt={staticimage.image}
                  className={`${tabId === staticimage.id ? "block" : "hidden"}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
