"use client";

import { motion } from "framer-motion";

import { fadeIn } from "../utils/motion";
import Button from "./common/Button";

const PricingCard = ({
  index,
  subheading,
  price,
  list,
  headtitle,
  subtitle,
  icon,
  buttonName
}) => {
  return (
    <motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      key={index}
      className={`w-80 flex flex-col p-5  text-center items-start text-gray-900  2xl:pt-8 pricing_card `}
    >
       <span className="w-10 h-10 mb-5 mx-auto"> {icon}</span>
      <p className="font-bold text-[#263B7E] mx-auto sm:text-xl">{subheading}</p>
      <div className="flex justify-center items-baseline mx-auto my-8">
        <span className="mr-2 text-5xl font-extrabold">₹ {price}</span>
        <span className="text-gray-800 text-base font-bold">
          / {index === 1 ? "Project" : "Year"}
        </span>
      </div>
      <p className="font-medium mx-auto mb-4 text-black sm:text-lg ">{headtitle}</p>
      {subtitle ? (
        <p className="font-medium mx-auto mb-4 text-black sm:text-base  ">{subtitle}</p>
      ) : null}
      <ul className="mb-6 space-y-4 text-left">
        {list.map((data, index) => {
          return (
            <li key={index} className="flex items-center space-x-3 text-sm ">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.017334"
                  width="24"
                  height="24"
                  rx="12"
                  fill="url(#paint0_linear_1_195)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.5965 7.407L10.4365 14.317L8.5365 12.287C8.1865 11.957 7.6365 11.937 7.2365 12.217C6.8465 12.507 6.7365 13.017 6.9765 13.427L9.2265 17.087C9.4465 17.427 9.8265 17.637 10.2565 17.637C10.6665 17.637 11.0565 17.427 11.2765 17.087C11.6365 16.617 18.5065 8.427 18.5065 8.427C19.4065 7.507 18.3165 6.69701 17.5965 7.39701V7.407Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_195"
                    x1="0.5"
                    y1="0.017334"
                    x2="27.4101"
                    y2="3.91251"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2E31FF" />
                    <stop offset="1" stop-color="#F69722" />
                  </linearGradient>
                </defs>
              </svg>
              <span className="text-[#667085] text-base font-normal">{data}</span>
            </li>
          );
        })}
      </ul>
      <div className="flex items-center w-full justify-center">
        <Button
          text={buttonName}
          to=""
          border="rounded-[8px]"
          bgColor="button-grdient"
          width="w-full mx-auto"
        />
      </div>
    </motion.div>
  );
};

export default PricingCard;