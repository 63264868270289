"use client";

import { motion } from "framer-motion";

import { NewHeading, SubNewHeading } from "../CustomTexts";
import { fadeIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";
import Button from "../common/Button";


const LearnMore = ({icon,title,description,wrapperClass="lg:flex-row",image,bgColor="bg-[#E1F1ED]"}) => (
  <section className={` relative font-zohoPuvi z-10 `}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidthNew} py-5 lg:py-14 px-4 justify-center mx-auto w-full rounded-[38px] flex  ${wrapperClass} ${bgColor} flex-col-reverse`}>
      <motion.div
        variants={fadeIn("left", "tween", 0.2, 1)}
        className="flex max-w-md justify-center flex-col">
           <img
          src={icon}
          alt="img"
          className=" object-contain w-[58px] h-[58px]"
        />
        <NewHeading
          title={
            <>
              {title}
            </>
          }
          className="text-black"
        />
        <div className="mt-[10px] flex flex-col gap-[24px]">
          <SubNewHeading
            title={
              <> {description} </>
            }
            textStyles="w-4/5 !text-[#292929]"
          />
        </div>
        <div className="mt-9">

        <Button text="Learn More" to="https://platform.realiti.io/" classNames='!px-11 py-[18px]' width='w-[202px]'/>
        </div>

       
      </motion.div>
      <motion.div
        variants={fadeIn("right", "tween", 0.2, 1)}
        className={``}>
        <img
          src={image}
          alt="img"
          className=" object-contain"
        />
      </motion.div>
    </motion.div>
  </section>
);

export default LearnMore;
