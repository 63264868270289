import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import { staggerContainer } from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar } from "../components";
import { BgGradient } from "../assets";
import Address from "../sections/Address";





const ContactUs = () => {

    const [showFloatHeader, setShowFloatHeader] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);


    useEffect(() => {
        document.getElementById("layout").addEventListener("scroll", () => {
            document.getElementById("layout").scrollTop > 50
                ? setShowFloatHeader(true)
                : setShowFloatHeader(false);
        });
    });


    return (
        <Layout
            id="layout"
            showFloatHeader={showFloatHeader}
            showSideBar={showSideBar}
            setShowSideBar={setShowSideBar}>
            <div
                style={{ backgroundImage: `url(${BgGradient})` }}
                className="bg-no-repeat ">
                <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

                <section
                    className={`${styles.yPaddings} mt-6  w-full px-4 lg:px-10  flex flex-col lg:flex-row`}>

                    <motion.div
                        variants={staggerContainer}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.25 }}
                        className={`${styles.innerWidth} relative mx-auto pb-10 flex flex-col`}>

                        <Address />
                    </motion.div>


                </section>
            </div>

            <div className="relative flex items-center justify-center px-10 lg:px-2 mb-[40px]">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15665.63392365624!2d76.9466746!3d11.0079477!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8591d2ec0f5ff%3A0xc329a9a2e30f5b8e!2sRealiti.io!5e0!3m2!1sen!2sin!4v1699560452596!5m2!1sen!2sin" width="1300" height="450" title="location" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <Footer />
        </Layout>
    );
};

export default ContactUs;
