import { motion } from "framer-motion";
import { fadeIn } from "../../utils/motion";

import { TestimonialCard1, TestimonialCard2 } from "../../assets";
const TestimonialCard = () => {
  return (
    <>
      <motion.div
        variants={fadeIn("right", "spring", 1 * 0.5, 0.75)}
        className={`w-full p-1 lg:px-4 py-4 flex  flex-col space-y-2 items-center  justify-center border-[#F7991E] testimonial_card rounded-[26px] border`}
      >
        <div className="p-4 lg:p-6 w-full text-left">
          <div>
            <img src={TestimonialCard2} alt="test-card" />
            <p className="text-[#4F4F4F] text-xl font-normal mt-7">
              Our business and the Realiti tool get along seamlessly. Instead of
              projecting the entire project, we were able to project a specific
              flat/villa to our potential costumer. It also allows us to adjust
              the unit's availability on the go. It provides a lot of extra
              features, such as the ability to add amenities, photographs, and
              so on.
            </p>
            <h6 className="mt-10 text-xl text-[#0B0F20] font-medium">
              - Mr.Dhilip Kumar,
              <br /> Marketing manager
            </h6>
          </div>
        </div>
      </motion.div>
      <motion.div
        variants={fadeIn("right", "spring", 1 * 0.5, 0.75)}
        className={`w-full p-1 lg:px-4 py-4 flex  flex-col space-y-2 items-center  justify-center border-[#F7991E] testimonial_card rounded-[26px] border`}
      >
        <div className="p-4 lg:p-6 w-full text-left">
          <div>
            <img src={TestimonialCard1} alt="test-card" />
            <p className="text-[#4F4F4F] text-xl font-normal mt-7">
              We wish to place on record the power of your digital processes for
              generating leads in the Real estate sector. Your process had been
              well thought of at all stages till identification of authentic
              prospective buyers.
              <br />
              <span className="font-medium mt-3">Wish you All the Best!</span>
            </p>
            <h6 className="mt-10 text-xl text-[#0B0F20] font-medium">
              -B.Suresh Babu,
              <br />
              Managing Director
            </h6>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default TestimonialCard;
