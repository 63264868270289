"use client";

import { motion } from "framer-motion";
import styles from "../../styles";
import {  NewHeading, SubHeading } from "../CustomTexts";
import ManageCard from "../ManageCard";
import { ManageData, ProductData } from "../../constants";
import { staggerContainer } from "../../utils/motion";
import ProductCard from "./ProductCard";



const Product = () => {

    return (
        <section className={`${styles.paddings} bg-[#F5F7E1]`}>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.25 }}
                className={`${styles.innerWidthNew} mx-auto flex flex-col`}>
                <div className="flex flex-col">
                    <SubHeading
                        title={
                            <>
                                An Real estate Online Store to
                            </>
                        }
                        textStyles="text-center text-lg"
                    />
                    <NewHeading
                        title={
                            <>
                                Manage, Market & Sell Unit Homes
                                {/* <span className="text-primary">super broker </span> */}
                                <br className="md:block hidden" />
                                Faster. Online.
                            </>
                        }
                        textStyles="text-center"
                    />

                </div>

                <div className="px-1 lg:px-5 mt-[50px] grid grid-cols-1 justify-items-center mx-auto md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 lg:gap-6">
                    {ProductData.map((world, index) => (
                        <ProductCard
                            key={world.id}
                            {...world}
                            index={index}

                        />
                    ))}
                </div>
            </motion.div>
        </section>
    );
};

export default Product;
