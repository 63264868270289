export const links = [
    {
      name: "Features",
      secondList:"Resource",
      resourcename: "Company",
      thirdList:'Resource',
      // productname:'Products',
      submenu: true,
      sublinks: [
        {
          sublink: [
           
            { name: "Web Portal (Self-Branded Onlinefront)", link: "/web-portal" , externalLink: true },
            { name: "IDX (Partner Secure Data Exchange)", link: "/channel-partners", externalLink: true },
            { name: "MLS (Stock and Cloud)", link: "/stocks", externalLink: true },
            { name: "CRM (Lead Magnet)", link: "/leads", externalLink: true },
          ],
        },   
      ],
      resourcesarray: [
        {
          sublink: [
            { name: "About", link: "/about" },
            { name: "Partner", link: "https://platform.realiti.io/refer", externalLink: true },
            { name: "Careers", link: "https://www.realiti.io/people ", externalLink: true },
            { name: "Contact", link: "https://platform.realiti.io/contact ", externalLink: true },
          ],
        }, 
      ],
      productarray: [
    
        {
          sublink: [
           
            { name: "Documentation", link: "https://help.realiti.io" , externalLink: true },
            { name: "Help & Support", link: "https://help.realiti.io", externalLink: true },
            { name: "Feature Requests", link: "https://help.realiti.io", externalLink: true },
            { name: "Blog", link: "https://realiti.org/blog", externalLink: true },
            { name: "Webinars", link: "https://realiti.org/webinars", externalLink: true },

          ],
        },         
      ],
      // store:[
      //   {
      //     sublink:[
      //       { name: "Online Store", link: "/online-store" },

      //     ]
      //   }
      // ]
    },
  ];