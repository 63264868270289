import React, { useEffect, useState } from 'react'
import { Footer, Navbar } from "../../components";
import {  BgGradient } from "../../assets";
import Layout from '../../components/Layout';
import ScrollSpyDemo from '../../components/OnlineStore/Scrollspy';


const OnlineStorePage = () => {

  const [showFloatHeader, setShowFloatHeader] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if(scrollPosition > 50){
          setShowFloatHeader(true)
        }else{
          setShowFloatHeader(false)
        }
    };
    
        handleScroll();
        document.addEventListener('scroll', handleScroll, true);
    
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <Layout id="layout" showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}>

      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat">
        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />    
        <ScrollSpyDemo />

      </div>

      <Footer />
    </Layout>
  );
};

export default OnlineStorePage;
