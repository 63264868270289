import {SliderImage} from '../../assets'

const SliderCard=()=>{
    return(
        <div>
           <img src={SliderImage} alt='slider-image' />
           <h6 className='text-[#2A296A] text-center font-bold text-[35px] leading-10 mt-10'>Pick Your Theme</h6>
           <p className='mt-3 text-center text-[#595B62] font-normal text-2xl'>Align the website with your logo colors, images, and attributes that reflect well with your brand!</p>
        </div>
    )
}

export default SliderCard;