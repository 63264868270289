'use client';
import { motion } from 'framer-motion';

import { client } from "../../constants";
import styles from "../../styles";
import { staggerContainer } from "../../utils/motion";
import { NewHeading } from "../CustomTexts";
import LogoCard from "../LogoCard";



const DevelopersTrustedCompany = () => {

  return (
    <section className={`${styles.paddings}`} >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}
      >
        <NewHeading
          title={<>Trusted by some of the leading builders</>}
          textStyles="mx-auto text-center max-w-[850px]"
        />
        <div className="mt-[50px] flex lg:flex-row flex-col px-2  gap-5">
          {client.map((logos, index) => (
            <LogoCard
              key={index}
              {...logos}
              index={index}
            
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default DevelopersTrustedCompany;
