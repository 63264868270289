"use client";

import { motion } from "framer-motion";


import FutureCard from "../FutureCard";
import styles from "../../styles";
import { staggerContainer } from "../../utils/motion";
import { NewHeading } from "../CustomTexts";
import { FutureData } from "../../constants";
import TestimonialCard from "./TestmonialCard";

const TestimonialWeb = () => {

  return (
    <section id="features"
      
      >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidthNew} mx-auto flex flex-col px-0 lg:px-12`}>
        <NewHeading
          title={
            <>
               Real-life <span className="text-primary"> results and revenue</span>
            </>
          }
          textStyles="text-center !text-[#0E2278] !mt-20"
        />
        <p className="text-sm mt-3 md:text-lg text-center font-zohoPuvi tracking-normal text-font_primary xl:leading-snug">
        See how Realiti-preneurs like you have smashed their sales success goals
                </p>
        <div className=" px-2 lg:px-4 mt-[50px] flex lg:flex-row flex-col gap-5 mb-8">
          <TestimonialCard />
        </div>
      </motion.div>
    </section>
  );
};

export default TestimonialWeb;
