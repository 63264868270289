import React from 'react';
import PropTypes from 'prop-types';
import InnerHTML from 'html-react-parser';
// import '../../styles/styles.scss'
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { QuoteImg } from '../../assets';


// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y])

const SwiperSlider = (props) => {
  return (
    <>
      <Swiper
        spaceBetween={props.spaceBetween}
        loop={true}
        autoplay={{ delay: props.slideDeley }}
        slidesPerView={props.slidesPerView}
        pagination={false}
        scrollbar={false}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
      >
        {props.slides.map((slide, index) => (
          <SwiperSlide className='p-2 lg:p-8 font-zohoPuvi' key={index}>
            <div className="flex flex-col lg:flex-row bg-white shadow-lg border p-12 lg:p-16 items-center justify-center space-x-4 rounded-30px">
            {slide.img && <img src={slide.img} className='rounded-10px w-36 h-36 lg:w-56 lg:h-56' alt="testimonial" />}
              <div className="flex flex-col">

                <div className="flex items-center justify-end ">
                  <img
                    className="absolute right-0 pr-6 p-0 lg:p-16 mt-4  w-[14%] lg:w-[16%]"
                    src={QuoteImg}
                    alt="Workplace"
                  />
                </div>
                {slide.content && InnerHTML(slide.content)}
                {slide.cite && InnerHTML(slide.cite)}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

SwiperSlider.propTypes = {
  slides: PropTypes.array,
}

export default SwiperSlider
