import React, { useState } from "react";
// import CloseIcon from './icons/Close'
// import { getElementHeight } from '../../utils/utils'
import Button from "./Button";
import NavLink from "./Navlink";
import CloseIcon from "../../assets/icons/close";
import { Link } from "react-router-dom";
import { links } from "./NavList";

const Sidebar = ({ showSideBar, setShowSideBar }) => {
  const [subHeading, setSubHeading] = useState("");
  const [subHeading2, setSubHeading2] = useState("");
  const [subHeading3, setSubHeading3] = useState("");

  return (
    <>
      <div
        id="sidebar"
        className={`h-screen block xl:hidden fixed transition-width duration-500 ease-in-out ${
          showSideBar ? "right-0 w-4/5 md:w-3/5" : "right-0 w-0 overflow-hidden"
        } bg-white`}
        style={{ zIndex: 100001 }}
      >
        {/* Close Icon */}
        <div
          className="px-6 py-6 flex justify-end"
          onClick={() => setShowSideBar(!showSideBar)}
        >
          <CloseIcon />
        </div>

        <div className="px-7 space-y-4 flex flex-col">
          {/* <div onClick={() => setShowSideBar(false)}>
            <NavLink to="/" text="Home" />
          </div> */}
          <div onClick={() => setShowSideBar(false)}>
            <NavLink to="/agent" text="For Agent " />
          </div>
          <div onClick={() => setShowSideBar(false)}>
            <NavLink to="/developers" text="For Developer" />
          </div>
          <div>
            {/* <NavLink to="/" text="Community" /> */}
            {links.map((link) => (
              <div>
                {/* Mobile menus */}
                <div>
                  {/* sublinks */}
                  {link.sublinks.map((slinks) => (
                    <div className="mb-5">
                      <div>
                        <h1
                          onClick={() =>
                            subHeading !== slinks.Head
                              ? setSubHeading(slinks.Head)
                              : setSubHeading("")
                          }
                          className=" text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer flex justify-between items-center md:pr-0 pr-5"
                        >
                          {link.name}

                          <span className="text-xl md:mt-1 md:ml-2   group-hover:rotate-180 group-hover:-mt-2">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.44333 8.51584L11 12.0725L14.5567 8.51584C14.9142 8.15834 15.4917 8.15834 15.8492 8.51584C16.2067 8.87334 16.2067 9.45084 15.8492 9.80834L11.6417 14.0158C11.2842 14.3733 10.7067 14.3733 10.3492 14.0158L6.14166 9.80834C5.78416 9.45084 5.78416 8.87334 6.14166 8.51584C6.49916 8.1675 7.08583 8.15834 7.44333 8.51584Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </h1>
                        <div
                          className={`${
                            subHeading === slinks.Head ? "md:hidden" : "hidden"
                          } list-none`}
                        >
                          {slinks.sublink.map((slink) => (
                            <li className="text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer py-3 pl-6">
                              <Link to={slink.link}>{slink.name}</Link>
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}

                  {link.productarray.map((slinks) => (
                    <div className="mb-5">
                      <div>
                        <h1
                          onClick={() =>
                            subHeading2 !== slinks.Head
                              ? setSubHeading2(slinks.Head)
                              : setSubHeading2("")
                          }
                          className=" text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer flex justify-between items-center md:pr-0 pr-5"
                        >
                          {link.secondList}

                          <span className="text-xl md:mt-1 md:ml-2 group-hover:rotate-180 group-hover:-mt-2">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.44333 8.51584L11 12.0725L14.5567 8.51584C14.9142 8.15834 15.4917 8.15834 15.8492 8.51584C16.2067 8.87334 16.2067 9.45084 15.8492 9.80834L11.6417 14.0158C11.2842 14.3733 10.7067 14.3733 10.3492 14.0158L6.14166 9.80834C5.78416 9.45084 5.78416 8.87334 6.14166 8.51584C6.49916 8.1675 7.08583 8.15834 7.44333 8.51584Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </h1>
                        <div
                          className={`${
                            subHeading2 === slinks.Head ? "md:hidden" : "hidden"
                          } list-none`}
                        >
                          {slinks.sublink.map((slink) => (
                            <li className="text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer py-3 pl-6">
                              <Link to={slink.link}>{slink.name}</Link>
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                   {link.resourcesarray.map((slinks) => (
                    <div className="mb-5">
                      <div>
                        <h1
                          onClick={() =>
                            subHeading3 !== slinks.Head
                              ? setSubHeading3(slinks.Head)
                              : setSubHeading3("")
                          }
                          className=" text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer flex justify-between items-center md:pr-0 pr-5"
                        >
                          {link.resourcename}

                          <span className="text-xl md:mt-1 md:ml-2 group-hover:rotate-180 group-hover:-mt-2">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.44333 8.51584L11 12.0725L14.5567 8.51584C14.9142 8.15834 15.4917 8.15834 15.8492 8.51584C16.2067 8.87334 16.2067 9.45084 15.8492 9.80834L11.6417 14.0158C11.2842 14.3733 10.7067 14.3733 10.3492 14.0158L6.14166 9.80834C5.78416 9.45084 5.78416 8.87334 6.14166 8.51584C6.49916 8.1675 7.08583 8.15834 7.44333 8.51584Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </h1>
                        <div
                          className={`${
                            subHeading3 === slinks.Head ? "md:hidden" : "hidden"
                          } list-none`}
                        >
                          {slinks.sublink.map((slink) => (
                            <li className="text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer py-3 pl-6">
                              <Link to={slink.link}>{slink.name}</Link>
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                
                  
                </div>
              </div>
              
            ))}
          </div>
          <Button
            text="Login"
            to="https://app.realiti.io/"
            bgColor="bg-black"
          />
        </div>
      </div>
    </>
  );
};

export default Sidebar;