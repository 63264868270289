"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { fadeIn, staggerContainer } from "../utils/motion";
import TableCard from "../components/TableCard";
import { NewHeading, SubNewHeading } from "../components/CustomTexts";
import Button from "../components/common/Button";
import {TickIcon} from '../assets'
const HowWeWork = () => (
  <section className={`${styles.paddings} relative z-10 mb-5`}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto flex flex-col lg:flex-row justify-between items-center`}
    >
      <div className="max-w-xl">
        <NewHeading
          title={
            <>
              <span>
                How We <span className="text-primary">Work</span>
              </span>
            </>
          }
        />
        <SubNewHeading
          title={<>Find, share, and manage all your properties in one place.</>}
        />
          <div className="flex flex-col gap-y-4 mt-[35px]">
             <div className="flex items-center gap-x-[14px]">
                    <img src={TickIcon} alt="tick-icon"/>
                    <p className="text-base font-normal text-[#595B62]">Connect & build your private community</p>
             </div>
             <div className="flex items-center gap-x-[14px]">
                    <img src={TickIcon} alt="tick-icon"/>
                    <p className="text-base font-normal text-[#595B62]">Collaborate with fellow brokers, builders, and bankers</p>
             </div>
             <div className="flex items-center gap-x-[14px]">
                    <img src={TickIcon} alt="tick-icon"/>
                    <p className="text-base font-normal text-[#595B62]">Communicate effectively with your buyers</p>
             </div>
          </div>
        <Button
          text="Get Started"
          to="https://platform.realiti.io/"
          classNames="!px-11 py-4.5"
          width="w-[202px] !rounded-[40px] w-[280px] border-[3px] border-[#4A4A4A] mt-[30px]"
        />
      </div>

      <motion.div
        variants={fadeIn("up", "tween", 0.3, 1)}
        className="relative px-2 flex-col mt-20 flex w-auto  justify-center"
      >
        <TableCard />
      </motion.div>
    </motion.div>
  </section>
);

export default HowWeWork;
