'use client';
import { motion } from 'framer-motion';

import styles from '../styles';
import { client } from '../constants';
import { staggerContainer } from '../utils/motion';
import LogoCard from '../components/LogoCard';
import {  NewHeading, SubHeading } from '../components/CustomTexts';

const TrustedBy = () => {

  return (
    <section className={`${styles.paddings}`} >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}
      >
        <NewHeading
          title={<>Trusted by <span className='text-primary'>750+</span> Realtors</>}
          textStyles="text-center"
        />
        <SubHeading
          title={<>Join 750+ Builders, Brokers, and Bankers who have trusted  <span className='text-primary'>Realiti.io</span></>}
          textStyles="text-center"
        />
        <div className="mt-[50px] flex lg:flex-row flex-col px-2  gap-5">
          {client.map((logos, index) => (
            <LogoCard
              key={index}
              {...logos}
              index={index}
            
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default TrustedBy;
