"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { insights } from "../constants";
import LearnMore from "../components/ForAgent/LearnMore";

const Insights = () => (
  <section className={`${styles.paddings} relative z-10`}>
    <motion.div
      className={`${styles.innerWidth} mx-auto flex flex-col`}>
      <div className="mt-[50px] flex flex-col gap-y-6">
        {insights.map((item, index) => (
          <LearnMore title={item.title} image={item.imgUrl} wrapperClass={index=== 3|| index=== 1?'lg:flex-row-reverse gap-x-10':'lg:flex-row'}  bgColor={item.bg} description={item.subtitle}/>

        ))}
      </div>
    </motion.div>
  </section>
);

export default Insights;
