
const TableCard = () => {
    const dataArray = [
        {
            id: 1,
            work: 'Due diligence',
            Realiti: '<48h',
            Other: '2-3 weeks'
        },
        {
            id: 2,
            work: 'Realtors’ Markup',
            Realiti: 'Nil',
            Other: '40%-100%'
        },
        {
            id: 3,
            work: 'Retainers',
            Realiti: 'Nil',
            Other: '$$$'
        },
        {
            id: 4,
            work: 'Average Time to Partner',
            Realiti: 'Immediate',
            Other: '10 days'
        },
        {
            id: 5,
            work: 'Quality Guarantee',
            Realiti: 'Yes',
            Other: 'No'
        },
    ]


    return (

        <div className="relative overflow-x-auto rounded-lg">
            <table className="w-full text-sm text-left ">
                <thead className="text-sm lg:text-base text-gray-700 table_head_color font-medium">
                    <tr>
                        <th scope="col" className="px-4 lg:px-24 py-3">

                        </th>
                        <th scope="col" className="px-4 lg:px-20 py-3">
                            Realiti.io
                        </th>
                        <th scope="col" className="px-4 lg:px-16 py-3">
                            Other methods
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataArray.map((data, i) => {
                        return (
                            <tr key={i} className={` ${i % 2 != 0 ? 'bg-[#F8F8F8]' : 'bg-white'}  border-b`}>
                                <th scope="row" className="px-4 lg:px-24 py-4 font-medium text-primary whitespace-nowrap">
                                    {data.work}
                                </th>
                                <td className="px-4 lg:px-20 py-4">
                                    {data.Realiti}
                                </td>
                                <td className="px-4 lg:px-16 py-4">
                                    {data.Other}
                                </td>
                            </tr>
                        )
                    })}



                </tbody>
            </table>
        </div>

    )
}

export default TableCard;
