import { WebPlatformImage } from "../../assets";
import { NewHeading, SubNewHeading } from "../CustomTexts";
import { fadeIn } from "../../utils/motion";
import { MARKETIN_DATA } from "../../constants";
import { motion } from "framer-motion";

export const ScrollCard = () => {
  return (
    <div>
      <div className="flex lg:flex-row flex-col">
        <div className="max-w-xl">
          <NewHeading
            title={
              <>
                <span className="text-primary">
                  Integrate customer service{" "}
                </span>
                with the rest of your company
              </>
            }
            textStyles="!text-[#0E2278] relative z-[0]"
          />
          <SubNewHeading
            title={
              <>
                {" "}
                Get more out of Zoho Desk by connecting it with all the other
                software your team uses
              </>
            }
            textStyles="w-4/5 !text-[#292929]"
          />{" "}
        </div>

        <img src={WebPlatformImage} alt="web-platform" />
      </div>

      <motion.div
        variants={fadeIn("right", "tween", 0.2, 1)}
        className={`grid md:grid-cols-2 grid-cols-1 md:gap-x-[60px] md:gap-y-[45px] gap-5`}
      >{MARKETIN_DATA.map((data)=>{
        return(
            <div className="max-w-[460px]">
            <img src={data.image} alt="onboarding" />
            <h6 className="text-[#121127] text-2xl font-semibold mb-3">{data.title}</h6>
            <p className="text-black font-medium text-xl">{data.desc}</p>
        </div>
        )
      })}
       

      </motion.div>
    </div>
  );
};
