"use client";

import { motion } from "framer-motion";

import { NewHeading, SubNewHeading } from "../CustomTexts";
import { fadeIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";
import Button from "../common/Button";
import {WebPlatformImage} from '../../assets'
import { MARKETIN_DATA } from "../../constants";
const MarketingPlatform = () => (
  <section className={`${styles.paddings} relative font-zohoPuvi z-10 `}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidthXl} py-5 lg:py-14 px-4 justify-between mx-auto w-full flex lg:flex-row flex-col-reverse`}
    >
      <motion.div
        variants={fadeIn("left", "tween", 0.2, 1)}
        className="flex max-w-lg justify-center flex-col"
      >
        <NewHeading
          title={
            <>
              All-in-one{" "}
              <span className="text-primary">Sales & Marketing {""}</span>
              Platform
            </>
          }
          textStyles="!text-[#0E2278]"
        />
        <div className="mt-[10px] flex flex-col gap-[24px]">
          <SubNewHeading
            title={
              <>
                {" "}
                for you to put an end to using multiple tools to perform
                business operations
              </>
            }
            textStyles="w-4/5 !text-[#292929]"
          />
        </div>
        <div className="mt-9">
        <Button text="Get Started" to="https://platform.realiti.io/" classNames='!rounded-[40px] !w-[280px] py-5 !px-[66px] border-[3px] border-[#4A4A4A]'/>

        </div>
        <div className="mt-10">
            <img src={WebPlatformImage} alt="web-platform"/>
        </div>
      </motion.div>
      <motion.div
        variants={fadeIn("right", "tween", 0.2, 1)}
        className={`grid md:grid-cols-2 grid-cols-1 md:gap-x-[60px] md:gap-y-[45px] gap-5`}
      >{MARKETIN_DATA.map((data)=>{
        return(
            <div className="max-w-[460px]">
            <img src={data.image} alt="onboarding" />
            <h6 className="text-[#121127] text-2xl font-semibold mb-3">{data.title}</h6>
            <p className="text-black">{data.desc}</p>
        </div>
        )
      })}
       

      </motion.div>
    </motion.div>
  </section>
);

export default MarketingPlatform;
