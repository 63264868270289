import React, { useState } from "react";
import Button from "./common/Button";
import NavLink from "./common/Navlink";
import { Logo, MenuIcon } from "../assets";
import SubNavLink from "./common/SubNavLink";

const FloatHeader = ({ showFloatHeader, setShowSideBar }) => {
  const [zindex, setZindex] = useState(-1000);

  React.useEffect(() => {
    if (showFloatHeader) {
      setZindex(1000);
    } else {
      setZindex(-1000);
    }
  }, [showFloatHeader]);

  return (
    <nav
      className={` flex-col flex fixed w-full bg-white px-3 py-3  lg:px-2 border-b  justify-between items-center transition-opacity duration-300 ${
        showFloatHeader ? "opacity-100" : "opacity-0"
      }`}
      style={{ zIndex: zindex }}>
      <div className="w-[93%] mx-auto  flex justify-between items-center">
        {/* Logo */}
        <a
          href="/"
          className="group inline-flex flex-row items-center space-x-3 cursor-pointer">
          <img className="w-[140px]" src={Logo} alt="Logo" />
        </a>

        {/* Links */}
        <div className="hidden xl:flex flex-row items-center justify-between space-x-5">
          <NavLink to="/agent" text="For Agent " />
          <NavLink to="/developers" text="For Developers" />
          <SubNavLink />
          <Button text="Login" to="https://app.realiti.io/" bgColor="bg-black" />
        </div>

        {/* Collapse button for mobile view */}
        <div
          className="block xl:hidden cursor-pointer  bg-primary p-2 rounded-10px "
          onClick={() => setShowSideBar(true)}>
          <img src={MenuIcon} alt="icon" />
        </div>
      </div>
    </nav>
  );
};

export default FloatHeader;
