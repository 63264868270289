import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import { staggerContainer, textVariant2 } from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar } from "../components";
import { BgGradient } from "../assets";
import { Enjoy, PricingList } from "../sections";
import Categories from "../sections/Categories";
import Sales from "../sections/Sales";

const Price = () => {
  const [showFloatHeader, setShowFloatHeader] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    document.getElementById("layout").addEventListener("scroll", () => {
      document.getElementById("layout").scrollTop > 50
        ? setShowFloatHeader(true)
        : setShowFloatHeader(false);
    });
  });

  return (
    <Layout
      id="layout"
      showFloatHeader={showFloatHeader}
      showSideBar={showSideBar}
      setShowSideBar={setShowSideBar}
    >
      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat bg-cover"
      >
        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

        <section className={`${styles.yPaddings} w-full bg-cover `}>
          <motion.div
            variants={staggerContainer}
            initial=""
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} relative w-4/5  mx-auto pb-0 flex flex-col-reverse xl:flex-row`}
          >
            <div className="w-full px-10 lg:px-14 mt-8 xl:mt-14 mx-auto flex xl:items-center xl:justify-center ">
              <div className="flex flex-col items-center space-y-6">
                <motion.h1
                  variants={textVariant2}
                  initial="hidden"
                  whileInView="show"
                  className={`px-0 mt-[8px] font-zohoPuvi font-bold md:text-[55px] text-2xl text-black text-center`}
                >
                  Unlock Your <br/>
                  <span className="gradient-text">Real Estate</span> <br/>
                  Potential with Realiti.io 
                 <p className="text-sm lg:text-2xl font-extrabold pt-2 lg:pt-5"> Affordable Plans Tailored for Success</p>
                </motion.h1>

                
              </div>
            </div>
          </motion.div>
        </section>
      </div>

      <div className="relative px-2">
        <PricingList />
        <Categories />
        <Enjoy />
        <Sales />
      </div>
      <Footer />
    </Layout>
  );
};

export default Price;