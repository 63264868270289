"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { staggerContainer, textVariant2 } from "../utils/motion";
import { useState } from "react";
import { TESTIMONIAL_DATA } from "../constants";

const Enjoy = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (id) => {
    if(activeIndex === id){
       return setActiveIndex(0);
    }
    setActiveIndex(id);
  };
  return (
    <section className={`${styles.paddings}`}>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}
      >
        <div className="">
          <motion.h2
            variants={textVariant2}
            initial="hidden"
            whileInView="show"
            className={`mt-[8px] font-zohoPuvi font-bold md:text-[36px] text-[25px] text-black text-center`}
          >
            Frequently asked questions
          </motion.h2>
          <motion.h2
            variants={textVariant2}
            initial="hidden"
            whileInView="show"
            className={`mt-[8px] font-zohoPuvi font-bold text-base lg:text-xl text-[#667085] text-center`}
          >
            Everything you need to know about the product and billing.
          </motion.h2>
        </div>
        {TESTIMONIAL_DATA.map((data) => {
          return (
            <div className="border-b border-[#D9D9D9] last:border-transparent pt-6 pb-8 rounded-md">
              <div
                className="flex justify-between items-center  cursor-pointer"
                onClick={() => toggleAccordion(data.id)}
              >
                <h2 className="text-base lg:text-xl font-semibold text-[#101828]">
                  {data.question}
                </h2>
                { activeIndex === data.id ? (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 15H20M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15Z"
                      stroke="url(#paint0_linear_1_390)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1_390"
                        x1="2.5"
                        y1="2.5"
                        x2="30.5314"
                        y2="6.55748"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2E31FF" />
                        <stop offset="1" stop-color="#F69722" />
                      </linearGradient>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width="33"
                    height="36"
                    viewBox="0 0 33 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 12V22M10 17H20M27.5 17C27.5 23.9036 21.9036 29.5 15 29.5C8.09644 29.5 2.5 23.9036 2.5 17C2.5 10.0964 8.09644 4.5 15 4.5C21.9036 4.5 27.5 10.0964 27.5 17Z"
                      stroke="url(#paint0_linear_1_397)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1_397"
                        x1="2.5"
                        y1="4.5"
                        x2="30.5314"
                        y2="8.55748"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2E31FF" />
                        <stop offset="1" stop-color="#F69722" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
              </div>
              { activeIndex === data.id ? (
                <p className="text-[#667085] font-bold mt-2 text-sm lg:text-base">
                  {data.answer}
                </p>
              ) : null}
            </div>
          );
        })}
      </motion.div>
    </section>
  );
};

export default Enjoy;