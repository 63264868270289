import { useEffect, useRef, useState } from "react";

import { getDimensions, useIsBrowser, scrollTo } from '../../utils/helper';
import { ScrollCard } from "./ScrollCard";


export default function ScrollSpy() {
  const [visibleSection, setVisibleSection] = useState();

  const isBrowser = useIsBrowser();

  const mainRef = useRef(null)
  const section1 = useRef(null)
  const section2 = useRef(null)
  const section3 = useRef(null)
  const section4 = useRef(null)
  const section5 = useRef(null)

  const [sectionRefs] = useState([
    { section: 'section1', ref: section1 },
    { section: 'section2', ref: section2 },
    { section: 'section3', ref: section3 },
    { section: 'section4', ref: section4 },
    { section: 'section5', ref: section5 },
]);
const List = [
  {
    id: 1,
    title: "Web Portal (Self-Branded Onlinefront)",
    sectionId: 'section1',
    sectionRef: section1
  },
  {
    id: 2,
    title: "IDX (Partner Secure Data Exchange)",
    sectionId: 'section2',
    sectionRef: section2
  },
  {
    id: 3,
    title: "MLS (Stock on Cloud)",
    sectionId: 'section3',
    sectionRef: section3
  },
  {
    id: 4,
    title: "CRM (Lead Magnet)",
    sectionId: 'section4',
    sectionRef: section4
  },
];

useEffect(() => {
  const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const selected = sectionRefs.find(({ ref }) => {
          const ele = ref.current;
          if (ele) {
              const divDimension = getDimensions(ele);
              if (divDimension) {
                const { offsetBottom } = divDimension;

                  // TODO: Adjusted the scrollTo section position while scrolling. It previously handled as
                  // return scrollPosition < offsetTop && scrollPosition < offsetBottom;
                  return scrollPosition + 200 < offsetBottom;
              }
              return false;
          }
          return false;
      });

      if (selected && selected.section !== visibleSection) {
          setVisibleSection(selected.section);
      }
  };

  if (isBrowser) {
      handleScroll();
      document.addEventListener('scroll', handleScroll, true);
  }
  return () => {
      window.removeEventListener('scroll', handleScroll);
  };
}, [isBrowser, sectionRefs, visibleSection]);

const menuItem = (item, i) => {
  return (
    <div
        key={i}
        className={
          visibleSection === item?.sectionId ? "active cursor-pointer border-l-4 border-l-[#F7991E] border first:rounded-t-20px px-10 border-[#2A296A] last:border-b-transparent py-5 bg-[#2A296A]" : "px-10 first:rounded-t-20px cursor-pointer border py-5 last:border-b-transparent border-[#D8D8D8]"
        }
        onClick={() => {
          if (item?.sectionRef?.current) {
            scrollTo(item?.sectionRef.current)
          }
          }
        }
      >
        <a
          href={`#section-${i}`}
          style={{
            color:
              visibleSection === item?.sectionId ? "#FFFFFF" : "#2A296A",
            fontSize: 17,
            fontWeight: 600,
          }}
          className="whitespace-nowrap"
        >
          {item.title}
        </a>
      </div>
  )
}

const rightSideSection = () => {
  return (
    <>
      <div
        id={'section1'}
        ref={section1}
        className={
          visibleSection === section1?.current?.id ? "active" : ""
        }
        style={{
          paddingBlockStart: 80,

          color: "#000",
          fontSize: "2rem",
        }}
      >
        <ScrollCard />
      </div>

      <div
        id={'section2'}
        ref={section2}
        className={
          visibleSection === section2?.current?.id ? "active" : ""
        }
        style={{
          paddingBlockStart: 80,

          color: "#000",
          fontSize: "2rem",
        }}
      >
        <ScrollCard />
      </div>

      <div
        id={'section3'}
        ref={section3}
        className={
          visibleSection === section3?.current?.id ? "active" : ""
        }
        style={{
          paddingBlockStart: 80,

          color: "#000",
          fontSize: "2rem",
        }}
      >
        <ScrollCard />
      </div>

      <div
        id={'section4'}
        ref={section4}
        className={
          visibleSection === section4?.current?.id ? "active" : ""
        }
        style={{
          paddingBlockStart: 80,
          color: "#000",
          fontSize: "2rem",
        }}
      >
        <ScrollCard />
      </div>
    </>
  )
}

  return (
    <div className="App">
      <div className="flex flex-col lg:flex-row">
            <div
              className="flex flex-col md:block gap-x-10 lg:bg-transparent bg-white md:gap-x-0 lg:ml-[50px] 2xl:ml-[70px]"
              data-cy="nav-wrapper"
              style={{
                listStyle: "none",
                position: "sticky",
                top: 70,
                paddingBlockStart: 20,
                height: "100%",
                zIndex:10
              }}
            >
              <h6 className="text-4xl mb-[30px] font-extralight text-black">Products</h6>
              <div className=" rounded-20px bg-white overflow-hidden product_sidebar">
                
              {List.map((item, i) => {
                return menuItem(item, i)
              })}
              </div>
            </div>
            <div
              data-cy="section-wrapper"
              style={{
                // width: "calc(100% - 450px)",
                marginInlineStart:'40px'
              }}
              className="section-right xl:w-[calc(100% - 450px)]"
              ref={mainRef}
            >
              {rightSideSection()}
            </div>
          </div>
    </div>
  );
}
