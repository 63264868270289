"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { PricingData } from "../constants";
import { staggerContainer } from "../utils/motion";
import { PricingCard } from "../components";


const PricingList = () => {
  // const [active, setActive] = useState(null);
  return (
    <section className={`${styles.paddings}`}>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}
      >
        <div className="mt-[10px] flex lg:flex-row flex-col items-center justify-center  gap-5 lg:gap-8">
          {PricingData.map((world, index) => (
            <PricingCard
                key={world.id}
                {...world}
                index={index}
            />
           
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default PricingList;