"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { staggerContainer, fadeIn } from "../utils/motion";
import Button from "../components/common/Button";
import {  RighforImg } from "../assets";
import { Heading, NewHeading, SubHeading, SubNewHeading } from "../components/CustomTexts";
import CheckSteps from "../components/CheckSteps";

const RealitiData =[
  'Fostering entrepreneurship',
  '15+ years in the industry',
  'Thoroughly verified profiles',
  'Strong partner network'
]

const RightFor = () => (
  <section className={`${styles.paddings} relative font-zohoPuvi z-10 bg-white`}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto w-full flex lg:flex-row flex-col-reverse`}>
      <motion.div
        variants={fadeIn("left", "tween", 0.2, 1)}
        className="flex w-full lg:w-3/5 justify-center flex-col">
        <NewHeading
          title={
            <>
              Is Realiti right for you?
            </>
          }
          className="text-black"
        />
        <div className="mt-[10px] flex flex-col gap-[24px]">
          <SubNewHeading
            title={
              <> The country’s leading builders use Realiti.io because of the
              quality of our partner network and our due diligence. When you join us, we’ll help
              you with everything it takes to scale. </>
            }
            textStyles="w-4/5"
          />

          {RealitiData.map((feature, index) => (
            <CheckSteps key={feature} text={feature} />
          ))}
        </div>
        {/* <div className="mt-6 flex flex-row space-x-4 px-2">
          <Button text="Get Started" to="https://platform.realiti.io/" />
        </div> */}
      </motion.div>
      <motion.div
        variants={fadeIn("right", "tween", 0.2, 1)}
        className={`flex-1 w-full lg:w-2/5    ${styles.flexCenter}`}>
        <img
          src={RighforImg}
          alt="img"
          className="w-[100%] h-[100%] object-contain"
        />
      </motion.div>
    </motion.div>
  </section>
);

export default RightFor;
