'use client';

import { motion } from 'framer-motion';
import { staggerContainer } from '../../utils/motion';
import styles from '../../styles';
import { NewHeading, SubHeading } from '../CustomTexts';

import {ReplacingImage} from '../../assets'

const Replacing = () => (
    <section className='bg-[#E1F1ED]'>
        <div className={`relative z-10 flex items-center justify-center`} >

          <motion.div 
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={` w-full flex mx-auto  flex-col items-center justify-center`} >
            <NewHeading title={<><br /> <span className="text-primary">One Platform.</span> Replacing 100s of Others!</>} textStyles="text-center !text-[#0E2278] mb-4" />
            <SubHeading
                title={<>With realiti.io, we help you focus on the business while all aspects of tech, end-to-end, is taken care of!
                </>}
                textStyles="text-left !mt-2"
            />
          </motion.div>
        </div>
            <div className='flex items-center justify-center flex-col'>
                <img src={ReplacingImage} alt="home-connect" className='w-full' />
            </div>
    </section>
);

export default Replacing;
