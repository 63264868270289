'use client';
import { motion } from 'framer-motion';
import { staggerContainer } from '../utils/motion';
import styles from '../styles';
import LogoCard from '../components/LogoCard';
import { NewHeading } from '../components/CustomTexts';
import { client } from '../constants';


const TrucstedCompany = () => {

  return (
    <section className={`${styles.paddings}`} >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}
      >
        <NewHeading
          title={<>Get access to over  <span className='text-primary'>        100+ 
          </span> leading builders in the country!</>}
          textStyles="mx-auto text-center max-w-[850px]"
        />
        <div className="mt-[50px] flex lg:flex-row flex-col px-2  gap-5">
          {client.map((logos, index) => (
            <LogoCard
              key={index}
              {...logos}
              index={index}
            
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default TrucstedCompany;
