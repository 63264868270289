
import SwiperSlider from './SwiperTestimonial'; 
import { NewHeading } from '../../components/CustomTexts'; 
import { TestimonialsImg3 } from '../../assets'; 
 
import TestimonialImage from '../../assets/images/foragent/testimonial.png' 
const Slides = [ 
  { 
    img: TestimonialImage, 
    cite: ` 
            <cite className="flex flex-col p-3"><span className="elementor-testimonial__name">Dhilip Kumar,</span> 
            <span className="elementor-testimonial__company"> Marketing Manager </span> 
            </cite> 
        `, 
    content: `
            <div className="swiper-slide-contents p-3 space-y-2"> 
            <div className="elementor-testimonial__text font-bold">Ananya’s Garden</div> 
            <div className="elementor-testimonial__text">Our Business and the Realiti tool get along seamlessly. Instead of projecting the entire project, we were able to project a specific flat/villa to our potential customer. It also allows us to adjust the unit’s availability on the go. It provides a lot of extra features, such as the ability to add amenities, photographs, and so on.</div> 
            </div>`, 
  }, 
   
  { 
    img: TestimonialsImg3, 
    cite: ` 
            <cite className="flex flex-col p-3"><span className="elementor-testimonial__name">-B.Suresh Babu,</span> 
            <span className="elementor-testimonial__company">Managing Director</span> 
            </cite> 
        `, 
    content: ` 
            <div className="swiper-slide-contents p-3 space-y-2"> 
            <div className="elementor-testimonial__text font-bold">Terraspace</div> 
            <div className="elementor-testimonial__text"> 
            We wish to place on record the power of your digital processes for generating leads in the Real estate sector. Your process had been well thought of at all stages till identification of authentic prospective buyers Wish you All the Best!</div> 
            </div>`, 
  }, 
  // { 
  //   img: TestimonialsImg2, 
  //   cite: ` 
  //           <cite className="flex flex-col p-3"><span className="elementor-testimonial__name">Dhilip Kumar,</span> 
  //           <span className="elementor-testimonial__company"> Marketing Manager </span> 
  //           </cite> 
  //       `, 
  //   content: ` 
  //           <div className="swiper-slide-contents p-3 space-y-2"> 
  //           <div className="elementor-testimonial__text font-bold">Green Park</div> 
  //           <div className="elementor-testimonial__text">Our Business and the Realiti tool get along seamlessly. Instead of projecting the entire project, we were able to project a specific flat/villa to our potential customer. It also allows us to adjust the unit’s availability on the go. It provides a lot of extra features, such as the ability to add amenities, photographs, and so on.</div> 
  //           </div>`, 
  // }, 
] 
 
const Testimonials = () => { 
  return ( 
    <> 
      <div className="relative pt-14 lg:pt-10"> 
        <div> 
          <NewHeading 
            title={<>Testimonials</>} 
            textStyles="text-center" 
          /> 
        </div> 
        <div className="flex flex-row items-center justify-center"> 
          <div className="hidden xl:block flex-col  w-11/12  "> 
            <SwiperSlider 
              slidesPerView={1} 
              slides={Slides} 
              spaceBetween={40} 
              slideDeley={5000} 
            /> 
          </div> 
 
          <div className="block xl:hidden pt-3 px-3 w-full"> 
            <SwiperSlider 
              slidesPerView={1} 
              slides={Slides} 
              spaceBetween={40} 
              slideDeley={5000} 
            /> 
          </div> 
        </div> 
      </div> 
    </> 
  ) 
} 
export default Testimonials