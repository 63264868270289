'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { staggerContainer, fadeIn } from '../utils/motion';
import Button from '../components/common/Button';

import PersonImage from '../assets/images/pricing-person.png'
import { Link, NavLink } from 'react-router-dom';
const Sales = () => (
    <section className={`relative z-10 mb-[50px] flex items-center justify-center `}>
        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} px-8 pb-4 lg:px-10 w-full lg:w-full flex items-center justify-center lg:flex-row flex-col gap-8 `}
        >
            <motion.div
                 
                variants={fadeIn('right', 'tween', 0.2, 1)}
                className="p-10 flex-1 flex items-center bg-cover bg-center rounded-2xl bg-[#F9FAFB] justify-center flex-col"
            >
                <img src={PersonImage} alt='person' />
                <h1 className="text-base  xl:text-xl mt-8 font-medium text-[#101828] text-center leading-8 ">
                Still have questions?
                </h1>
                <div className="mt-[8px] w-2/3 flex flex-col space-x-2 space-y-4">
                    <p className='text-sm text-[#667085] text-center'>Can’t find the answer you’re looking for? Please connect with our team.</p>
                    <div className="flex items-center justify-center mb-6">
                        <Button text="Get in touch" to="mailto:sales@realiti.io" border='rounded-[8px]' width='w-full lg:w-[130px] py-2.5' bgColor='button-grdient'/>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    </section>
);

export default Sales;