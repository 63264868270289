"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { fadeIn, staggerContainer } from "../utils/motion";
import {  ProfileData } from "../constants";
import { Line } from "../assets";
import { NavLink } from "react-router-dom";

export const ProfileCard = ({
  key,
  id,
  title,
  index,
  paragraph,
  bg,
  text,
  icon,
}) => {
  return (
    <>
<motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      key={key}
      className={`pb-8 w-full flex flex-col space-y-2 text-center items-center  justify-center  `}>
      <div className={`px-5 py-4 flex  font-bold text-xl text-center text-white  bg-[#121212] rounded-10px`}>
        {id}
      </div>
      <div className="w-full flex flex-col items-center  justify-center  text-center space-y-3 ">
        <h1 className="text-base  xl:text-[16px] font-medium text-black">
          {title}
        </h1>
        <p className="w-2/3 lg:w-4/5 text-sm  text-font_primary">{paragraph}</p>   
      </div>
    </motion.div>

    </>
    
  );
};

const Profile = () => {
    return (
  <section className={`${styles.paddings} relative z-10 `}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto flex flex-col lg:flex-row items-center justify-center`}>
      {ProfileData.map((world, index) => (
        <>
        <ProfileCard key={world.id} {...world} index={index} />
        <img src={Line} alt="line" className={`${world.id === 4 ? 'hidden' : 'hidden lg:block'}  `}  />
        </>
     ))}
    </motion.div>
    <div className="flex justify-center lg:justify-end container"> 
    <NavLink to={'https://platform.realiti.io/'}>
      <button className="px-3 py-2 flex items-center  justify-center text-sm font-Poppins lg:text-base w-[202px] text-white bg-primary !px-11 py-[18px] rounded-md">Get Started</button>
      </NavLink>
    </div>
  </section>
)};

export default Profile;
